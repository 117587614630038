.error {
  margin-top: 10px;
  color: red;
  font-family: sans-serif;
  font-size: small;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 600px;
}
